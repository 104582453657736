@font-face {
   font-family: 'mosk';
   src:
      url('../../assets/fonts/Mosk-Ultra-Bold-900.ttf') format('truetype');
   font-weight: 900;
   font-style: normal;
}

@font-face {
   font-family: 'mosk';
   src:
      url('../../assets/fonts/Mosk-Extra-Bold-800.ttf') format('truetype');
   font-weight: 800;
   font-style: normal;
}

@font-face {
   font-family: 'mosk';
   src:
      url('../../assets/fonts/Mosk-Bold-700.ttf') format('truetype');
   font-weight: 700;
   font-style: normal;
}

@font-face {
   font-family: 'mosk';
   src:
      url('../../assets/fonts/Mosk-Semi-Bold-600.ttf') format('truetype');
   font-weight: 600;
   font-style: normal;
}

@font-face {
   font-family: 'mosk';
   src:
      url('../../assets/fonts/Mosk-Medium-500.ttf') format('truetype');
   font-weight: 500;
   font-style: normal;
}

@font-face {
   font-family: 'mosk';
   src:
      url('../../assets/fonts/Mosk-Normal-400.ttf') format('truetype');
   font-weight: 400;
   font-style: normal;
}

@font-face {
   font-family: 'mosk';
   src:
      url('../../assets/fonts/Mosk-Light-300.ttf') format('truetype');
   font-weight: 300;
   font-style: normal;
}

@font-face {
   font-family: 'mosk';
   src:
      url('../../assets/fonts/Mosk-Extra-Light-200.ttf') format('truetype');
   font-weight: 200;
   font-style: normal;
}

@font-face {
   font-family: 'mosk';
   src:
      url('../../assets/fonts/Mosk-Thin-100.ttf') format('truetype');
   font-weight: 100;
   font-style: normal;
}

@font-face {
   font-family: 'mono-rgo';
   src:
      url('../../assets/fonts/MONO-RGOMODULAR-Bold.ttf') format('truetype');
   font-weight: 700;
   font-style: normal;
}

@font-face {
   font-family: 'mono-rgo';
   src:
      url('../../assets/fonts/MONO-RGOMODULAR-SemiBold.ttf') format('truetype');
   font-weight: 600;
   font-style: normal;
}

@font-face {
   font-family: 'mono-rgo';
   src:
      url('../../assets/fonts/MONO-RGOMODULAR-Regular.ttf') format('truetype');
   font-weight: 400;
   font-style: normal;
}

body {
   font-family: 'mosk';
}